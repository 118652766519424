import MuiLink, { LinkProps as MuiLinkProps } from "@material-ui/core/Link";
import clsx from "clsx";
import NextLink, { LinkProps as BaseNextLinkProps } from "next/link";
import React from "react";
import { Url } from "url";
import { flatten, useOurRouter } from "../hooks/useOurRouter";

interface NextLinkProps extends Omit<MuiLinkProps, "href">, BaseNextLinkProps {
  activeClassName?: string;
  naked?: boolean;
  href: string | Url;
}

const NextComposed = React.forwardRef<HTMLSpanElement, NextLinkProps>(({ href, as, ...rest }, ref) => {
  return (
    <NextLink href={href} as={as} passHref>
      <MuiLink ref={ref} {...rest} />
    </NextLink>
  );
});

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
export const Link = React.forwardRef<HTMLSpanElement, any>(
  ({ href, activeClassName = "active", className: classNameProps, naked, ...rest }: NextLinkProps, ref) => {
    const router = useOurRouter();

    const pathname = (!href || typeof href === "string" ? href : href.pathname) || "";
    const className = clsx(classNameProps, {
      [activeClassName]: router.pathname === pathname && activeClassName,
    });

    if (!!href && typeof href !== "string" && !!href.query)
      href.query = flatten(typeof href.query === "string" ? JSON.parse(href.query) : href.query) || null;

    if (!href) {
      return <MuiLink className={className} ref={ref} {...rest} />;
    }

    if (pathname.startsWith("http") || pathname.startsWith("//") || pathname.startsWith("mailto")) {
      return <MuiLink className={className} ref={ref} href={pathname} {...rest} />;
    }

    if (!naked) {
      return <NextComposed className={className} ref={ref} href={href} {...rest} />;
    }

    return <MuiLink component={NextComposed} className={className} ref={ref} href={pathname} {...rest} />;
  }
);
